/*doc
---
title: Universal Search
name: universal_search
category: React
---

This is a basic search component that, given a user input, will do a search
using the Search#universal_search endpoint.
Universal search returns results of different kinds, aggregated in a single list,
depending on which search types have been implemented on the backend.

For each of these types, a react component must be implemented to render information of
that specific type.

Each search result has the following form:

{
  type: string // The type of the result.
               //  Clients should use this to select the right rendering component,

  object: json data blob // This is the actual search result data the specific react component
                         // knows how to render. Every search result type comes with potentially different
                         // data.

  destination: url // The url where to send the user when the search result is returned.
}

PROPS:
OPTIONAL - maxResults: max number of results to display per user query

```rails_example
<%= react_component 'ReactComponents.UniversalSearch'%>
```
*/
import React from "react";
import _ from "lodash";
import GrComponentFactory from "../../../gr_component_factory";
import Reflux from "reflux";
import OnClickOutside from "react-onclickoutside";
import classNames from "classnames";
import keys from "../../../../modules/keys";
// updated all these paths

// STORE
import UniversalSearchStoreFactory from "../../../../react_stores/experimental/search/universal_search_store_factory";
// COMPONENTS
import UniversalSearchResults from "./universal_search_results";
import Spinner from "../../../spinner";
// MIXINS
import FactoryStoreMixin from "../../../../react_mixins/factory_store_mixin";

export default GrComponentFactory.createClass({
  displayName: "UniversalSearch",

  mixins: [OnClickOutside,
           Reflux.ListenerMixin,
           FactoryStoreMixin(UniversalSearchStoreFactory)],

  propTypes: {
    onChooseResult: React.PropTypes.func.isRequired,
    maxResults: React.PropTypes.number,
    searchPath: React.PropTypes.string.isRequired,
    closeAfterResultChosen: React.PropTypes.bool,
    autocompleteUrl: React.PropTypes.string.isRequired,
  },

  getDefaultProps() {
    return {
      maxResults: 7,
      closeAfterResultChosen: false,
    };
  },

  hide() {
    this.actions.setShowResultsContainer(false);
  },

  handleClickOutside() {
    this.hide();
  },

  handleSearch(event) {
    this.actions.search(event.target.value, this.props.autocompleteUrl, this.props.maxResults);
  },

  handleClick(event) {
    if (!_.isEmpty(this.state.query)) {
      this.handleSearch(event);
    }
  },

  handleChoice(book) {
    this.props.onChooseResult(book);
    if (this.props.closeAfterResultChosen) {
      this.hide();
    }
  },

  handleKeyPress(event) {
    const maxResults = Math.min(this.props.maxResults, this.state.results.length);
    if (keys.isEnter(event.keyCode) && this.state.activeResult !== null) {
      this.handleChoice(this.state.activeResult);
      event.preventDefault();
    } else if (keys.isArrowDown(event.keyCode)) {
      this.actions.selectNextResult(maxResults);
    } else if (keys.isArrowUp(event.keyCode)) {
      this.actions.selectPreviousResult(maxResults);
    } else if (keys.isEscape(event.keyCode)) {
      this.hide();
    }
  },

  renderInputIcon() {
    const searchIconClasses = classNames("searchBox__icon",
      { "searchBox__icon--loadingSpinner": this.state.showLoadingSpinner,
        "searchBox__icon--magnifyingGlass gr-iconButton": !this.state.showLoadingSpinner }
    );
    if (this.state.showLoadingSpinner) {
      return <span className={searchIconClasses}>
               <Spinner />
             </span>;
    } else {
      return <button type="submit"
                     className={searchIconClasses}
                     aria-label="Search" />;
    }
  },

  render() {
    const searchBoxClasses = classNames(this.withBemModifiers("searchBox"));
    const searchInputClasses = classNames(this.withBemModifiers("searchBox__input"));

    return(
      <div acceptCharset="UTF-8"
           className={searchBoxClasses}
           onKeyDown={this.handleKeyPress}>
        <form autoComplete="off"
              action={this.props.searchPath}
              className="searchBox__form"
              role="search"
              aria-label="Search for books to add to your shelves">
          <input className={searchInputClasses}
                 autoComplete="off"
                 name="q"
                 type="text"
                 placeholder="Search books/authors"
                 aria-label="Search books"
                 onClick={this.handleClick}
                 onChange={this.handleSearch}
                 aria-controls="searchResults" />
          {this.renderInputIcon()}
          { this.state.showResultsContainer ?
            <UniversalSearchResults {...this.props}
            {...this.state}
                               onChooseResult={this.handleChoice}
                               mouseoverAction={this.actions.selectSpecificResult}
            /> :
            null
          }
        </form>
      </div>
    );
  },

});
