import _ from "lodash";
import Reflux from "reflux";
import Freezer from "freezer-js";
import { httpGet } from "../../../modules/ajax/ajax_helper";

// ACTIONS
import UniversalSearchActionsFactory from "../../../react_actions/experimental/universal_search_actions_factory";

const factory = function () {

  const state = new Freezer({
    showLoadingSpinner: false,
    showResultsContainer: false,
    results: [],
    query: "",
    activeResult: null,
    currentInput: null,
  });

  const actions = UniversalSearchActionsFactory();

  const updateState = function (results) {
    const values = state.get();
    values.results.reset(results);
  };

  const DELAYED_SEARCH_MS = 300;

  const store = Reflux.createStore({
    listenables: actions,
    getInitialState: state.get,
    getState: state.get,
    actions,

    initializeWith(data) {
      state.get().set(
        {
          showLoadingSpinner: data.showLoadingSpinner,
          showResultsContainer: data.showResultsContainer,
          query: data.query,
          activeResult: data.activeResult,
        }).results.reset(data.results);
    },

    reset() {
      state.get().reset({});
    },

    hide() {
      state.get().set(
        {
          showLoadingSpinner: false,
          activeResult: null,
          showResultsContaine: false,
        }
      );
      this.trigger(state.get());
    },

    search(query, searchUrl, maxResults) {

      if (query == state.get().currentInput)
      {
        state.get().set("showLoadingSpinner", true);
        this.trigger(state.get());

        httpGet(searchUrl,
          {
            data: {
              format: "json",
              query,
              limit: maxResults,
            },
            success: (resp) => {
              actions.searchResultsFound(query, resp);
            },
          }
        );

      }
      else
      {
        // This is needed since there might be a delayed search waiting, in the moment when
        // the input field is cleared out.
        // In this case the search operation would not be delayed further
        // and would start while the input field is empty.
        // The result would be an empty field and the loading spinner visible.
        // It should never be the case that the flow gets to this point with
        // state.get().currentInput != "".
        this.hide();
      }
    },

    delayedSearch: _.debounce((store, query, searchUrl, maxResults) => {
      store.search(query, searchUrl, maxResults);
    }, DELAYED_SEARCH_MS),

    onSelectSpecificResult(selected) {
      state.get().set("activeResult", selected);
      this.trigger(state.get());
    },

    onSetShowResultsContainer(value) {
      state.get().set("showResultsContainer", value);
      this.trigger(state.get());
    },

    onSelectNextResult(maxResults) {
      const results = state.get().results;
      const currentIndex = _.indexOf(results, state.get().activeResult);
      state.get().set("activeResult", results[(currentIndex + 1) % maxResults]);
      this.trigger(state.get());
    },

    onSelectPreviousResult(maxResults) {
      const results = state.get().results;
      const activeResult = state.get().activeResult;
      const currentIndex = _.indexOf(results, activeResult);
      if (currentIndex === 0 || activeResult === null) {
        state.get().set("activeResult", results[maxResults - 1]);
      } else {
        state.get().set("activeResult", results[(currentIndex - 1) % maxResults]);
      }
      this.trigger(state.get());
    },

    onSearchResultsFound(query, results) {

      if (query == state.get().currentInput)
      {
        updateState(results);
        state.get().set(
          {
            query,
            showLoadingSpinner: false,
            activeResult: null,
            showResultsContainer: true,
          }
        );

        this.trigger(state.get());
      }
    },

    onSearch(query, searchUrl, maxResults) {
      query = query.trim();
      state.get().set("currentInput", query);

      if (!_.isEmpty(query))
      {
        this.delayedSearch(this, query, searchUrl, maxResults);
      }
      else
      {
        this.hide();
      }
    },

  });

  return store;
};

export default factory;
