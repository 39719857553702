import React from "react";
import GrComponentFactory from "../../../../gr_component_factory";
import GrPropTypes from "../../../../shared/gr_prop_types";
import classnames from "classnames";
import AuthorBadge from "../../../../author/author_badge";

export default GrComponentFactory.createClass({
  displayName: "UniversalSearchResultBook",

  propTypes: {
    result: GrPropTypes.searchResults.book().isRequired,
    onChooseResult: React.PropTypes.func.isRequired,
  },

  render(){
    const linkClasses = classnames("gr-mediaBox",
      "gr-book--navbar",
      "gr-hyperlink",
      "gr-hyperlink--naked",
      "gr-hyperlink--noUnderline",
      "gr-universalSearchResults__result");
    return <a className={linkClasses}
              href="#"
              aria-label="Search result"
              onClick={ () => { this.props.onChooseResult(this.props.result); }}>
             <img className="gr-mediaBox__media"
                  src={this.props.result.object.imageUrl}
                  alt="Cover pic" />
             <div className="gr-mediaBox__desc">
               <div className="gr-universalSearchResult__mainText gr-universalSearchResult__mainText--navbar u-defaultType">
                 {this.props.result.object.title}
               </div>
               <div className="gr-universalSearchResult__secondaryText gr-universalSearchResult__secondaryText--navbar">
                 by {this.props.result.object.authorName}
                 <AuthorBadge {...this.props.result.object} />
               </div>
             </div>
           </a>;
  },
});
