/*doc
---
title: Universal Search Results
name: universal_search_results
category: React
---

Displays a list of search results based on results passed down from
the Universal Search components. If no results are found, displays a no results
message.

Universal search returns results of different kinds, aggregated in a single list,
depending on which search types have been implemented on the backend.

For each of these types, a react component must be implemented to render information of
that specific type.

Each search result has the following form:

{
  type: string // The type of the result.
               //  Clients should use this to select the right rendering component,

  object: json data blob // This is the actual search result data the specific react component
                         // knows how to render. Every search result type comes with potentially different
                         // data.

  destination: url // The url where to send the user when the search result is returned.
}

PROPS:
REQUIRED - results: An array of results
         - maxResults: max number of results to display per user query

*/
import React from "react";
import _ from "lodash";
import classNames from "classnames";
import DropDownList from "../../../drop_down_list";
import UniversalSearchResultBook from "./results/universal_search_result_book";
import UniversalSearchResultAuthor from "./results/universal_search_result_author";
import UniversalSearchResultUser from "./results/universal_search_result_user";

import GrPropTypes from "../../../shared/gr_prop_types";

import GrComponentFactory from "../../../gr_component_factory";

// These are the search results type available mapping to their React component
// When a new search type is available, just add it to this map, and the right component
// to render is picked up by resolving the result type.
const searchResultsComponents = {
  author: UniversalSearchResultAuthor,
  book: UniversalSearchResultBook,
  friend: UniversalSearchResultUser,
};

export default GrComponentFactory.createClass({
  displayName: "UniversalSearchResults",

  propTypes: {
    maxResults: React.PropTypes.number.isRequired,
    query: React.PropTypes.string,
    results: React.PropTypes.array.isRequired,
    shelfName: React.PropTypes.string,
    searchPath: React.PropTypes.string,
    onChooseResult: React.PropTypes.func.isRequired,
    mouseoverAction: React.PropTypes.func.isRequired,
    activeResult: React.PropTypes.oneOfType([
      GrPropTypes.searchResults.author(),
      GrPropTypes.searchResults.book(),
      GrPropTypes.searchResults.user(),
    ]),
  },

  renderLastSearchResultItem(text) {
    const searchResultsLastItemClasses =
      classNames("gr-buttonAsLink",
        "searchResults__lastItem",
        this.withBemElement("gr-universalSearchResults", "lastItem"));

    return (
      <button className={searchResultsLastItemClasses} type="submit">
        {text}
      </button>
    );
  },

  renderResult(result) {
    const SearchResultComponent = searchResultsComponents[result.type];
    return (
      <div className={classNames("gr-universalSearchResults__item", { "gr-universalSearchResults__item--active": result === this.props.activeResult })}
        key={result.object.id}
        onMouseOver={() => { this.props.mouseoverAction(result); }}>
        <SearchResultComponent result={result} onChooseResult={this.props.onChooseResult} />
      </div>
    );
  },

  render() {
    const searchResultsClasses = classNames(this.withBemModifiers("gr-universalSearchResults"),
      "gr-box",
      "gr-box--withShadow");

    return(
      <div className={searchResultsClasses}
        id={`universalSearchResults${this.props.query}${this.props.results.length}`}
        aria-live="polite"
        aria-label="Universal search results">
        <DropDownList maxListItems={this.props.maxResults}>
          { this.props.results.length > 0 ?
            _.map(_.slice(this.props.results, 0, this.props.maxResults), (result) => this.renderResult(result)) :
            this.renderLastSearchResultItem(`Search for "${this.props.query}"…`)
          }
          { this.props.results.length > 0 ?
            this.renderLastSearchResultItem(`See all results for "${this.props.query}"`) :
            null }
        </DropDownList>
      </div>
    );
  },
});
